<template>
  <NuxtLayout v-if="data" :name="isAuthorisedUser ? 'default' : 'unstyled'" hide-footer>
    <template #header-content>
      <div
        v-if="isAuthorisedUser"
        class="grid grid-cols-2 gap-x-6 border-b px-6 py-5 text-sm lg:grid-cols-[max-content_1fr_max-content] lg:border-b lg:bg-white lg:py-4"
      >
        <NuxtLink :to="`/${data.partner.slug}/experiences`" class="flex items-center space-x-3 text-supplement">
          <Icon icon="chevron-left" />
          <span
            ><span class="hidden md:inline-block">{{ data?.partner.name }}&nbsp;</span>{{ $t("experiences") }}</span
          >
        </NuxtLink>

        <div class="flex items-center justify-end space-x-6 lg:justify-center">
          <!-- <PersonalisationsDropdown :shared-collection-hashid="data.hashid" :personalisations="data.personalisations" /> -->

          <div class="hidden lg:flex lg:items-center lg:space-x-3">
            <ToggleSwitch v-model="minimalUI" size="sm" />
            <div class="space-x-2.5">
              <span class="font-semibold">{{ $t("preview") }}</span>
              <span class="text-supplement opacity-0 transition-opacity" :class="{ 'opacity-100': minimalUI }">&mdash;</span>
              <span v-if="minimalUI" class="text-gray-500 opacity-0 transition-opacity" :class="{ 'opacity-100': minimalUI }">{{
                $t("preview_description")
              }}</span>
            </div>
          </div>
        </div>

        <div class="group hidden lg:flex lg:items-center lg:justify-end lg:space-x-6">
          <div>
            <span
              v-if="isExpired"
              class="rounded bg-gray-400 px-2 py-1 text-xs font-semibold text-gray-100 transition-colors group-hover:bg-red-600 group-hover:text-red-100"
              >EXPIRED</span
            >
          </div>
          <ShareButton
            :collection-name="data.collection.name"
            :social-share-text="data?.collection.social_share_text"
            :landing-page-url="data.landing_page_url"
            :partner-name="data.partner.name"
            :class="{ 'opacity-50 transition-opacity group-hover:opacity-100': isExpired }"
          />
        </div>
      </div>

      <div class="border-b p-2 lg:hidden">
        <nav class="grid grid-cols-3 rounded-md bg-slate-100 p-1.5 text-xs font-semibold">
          <a @click="activeMobileTab = 'edit'">
            <span
              class="flex items-center justify-center rounded-md px-3 py-2.5 transition-colors"
              :class="[activeMobileTab === 'edit' ? 'cursor-default bg-primary text-white shadow' : 'text-slate-500 hover:text-primary']"
              >Personalize</span
            >
          </a>
          <a @click="activeMobileTab = 'view'">
            <span
              class="flex items-center justify-center rounded-md px-3 py-2.5 transition-colors"
              :class="[activeMobileTab === 'view' ? 'cursor-default bg-primary text-white shadow' : 'text-slate-500 hover:text-primary']"
              >View</span
            >
          </a>
          <a @click="activeMobileTab = 'share'">
            <span
              class="flex items-center justify-center rounded-md px-3 py-2.5 transition-colors"
              :class="[activeMobileTab === 'share' ? 'cursor-default bg-primary text-white shadow' : 'text-slate-500 hover:text-primary']"
              >Share</span
            >
          </a>
        </nav>

        <div v-if="showModal === 'new_user'" class="mt-2 rounded-md bg-pink-500 px-6 py-4 text-sm font-medium text-white">
          <p>Welcome to your first content experience! <strong>Use the tabs above to personalize, view and share!</strong></p>
          <a class="mt-3 flex items-center space-x-2 text-white/80" @click="dismissNewUserMessage">
            <Icon icon="times" />
            <small>don't show this message again</small>
          </a>
        </div>
      </div>
    </template>

    <div class="relative flex grow flex-col overflow-hidden">
      <div class="flex grow overflow-y-auto overflow-x-hidden lg:overflow-hidden">
        <div
          v-if="isAuthorisedUser"
          class="w-full shrink-0 flex-col transition-all lg:flex lg:w-80 lg:border-r"
          :class="[minimalUI ? 'lg:-ml-80' : 'lg:ml-0', activeMobileTab === 'edit' ? 'flex' : 'hidden']"
        >
          <LazyCollectionLiveEditor
            :shared-collection-hashid="data.hashid"
            v-model:first-name="data.contact.first_name"
            v-model:last-name="data.contact.last_name"
            v-model:phone="data.contact.phone"
            v-model:logo="data.advisor.logo"
            v-model:personal-photo="data.advisor.personal_photo"
            v-model:personal-message="currentPersonalisation.personal_message"
            v-model:cta-display="data.cta.display"
            v-model:cta-link="data.cta.values.custom.link"
            v-model:cta-label="data.cta.values.custom.label"
            :cta-enabled="data.cta.enabled"
            :cta-options="data.cta.options"
          />
        </div>

        <div
          class="grow flex-col overflow-y-auto transition-spacing lg:flex"
          :class="[
            {
              'bg-slate-50': isAuthorisedUser,
              'lg:p-12': isAuthorisedUser && !minimalUI,
            },
            activeMobileTab === 'view' ? 'flex' : 'hidden',
          ]"
        >
          <div class="flex grow flex-col space-y-6">
            <LandingPage
              mode="shared-landing-page"
              :data="data"
              :current-personalisation="currentPersonalisation"
              :is-authorised-user="!!isAuthorisedUser"
              :class="{ 'bg-white shadow-lg': isAuthorisedUser }"
            />
          </div>
        </div>

        <div
          v-if="isAuthorisedUser"
          class="grow flex-col overflow-y-auto p-8 transition-spacing md:p-10 lg:hidden"
          :class="[activeMobileTab === 'share' ? 'flex' : 'hidden']"
        >
          <ShareButton
            :collection-name="data.collection.name"
            :social-share-text="data?.collection.social_share_text"
            :landing-page-url="data.landing_page_url"
            :partner-name="data.partner.name"
          />
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
const route = useRoute();
const { logout } = useAuth();
const { fbAppId, siteName } = useRuntimeConfig().public;
const user = useUser();

// Fetch data
const personalisationHashid = getVersionHashidFromUrl();

const { data } = await useAsyncData(`landing-page-${personalisationHashid}`, async () => {
  const response = await $larafetch<LaravelResponse<LandingPage>>(`/collections/${personalisationHashid}`);

  response.data.crop_data = {};

  if (typeof response.data.personalisation === "undefined") {
    response.data.personalisation = {
      personal_message: "",
    };
  }

  return response.data;
});

if (!data.value) {
  throw createError({ statusCode: 404, statusMessage: "Page Not Found", fatal: true });
}

const { data: landingPage } = useLandingPage();
landingPage.value = data.value;

// Handle redirect if required
if (data.value.collection.slug !== route.params.slug) {
  // @todo
  // await navigateTo(`/experiences/${data.value.collection.slug}-${personalisationHashid}`, {
  //   replace: true,
  //   redirectCode: 301,
  // });
}

const isAuthorisedUser = computed(() => data.value && user.value && data.value.owner_id === user.value.id);
const activeMobileTab = ref<"edit" | "view" | "share">(isAuthorisedUser.value ? "edit" : "view");

const { getDefaultPersonalisation, getPersonalisation } = useLandingPage();
const currentPersonalisation = ref(getPersonalisation(personalisationHashid));

// Redirect to default version if authorised user tries to access a collaborator version
if (isAuthorisedUser.value && currentPersonalisation.value.collaborator_version) {
  let defaultPersonalisation = getDefaultPersonalisation();

  if (!defaultPersonalisation) {
    const { data } = await useAsyncData(`landing-page-${personalisationHashid}-default-version`, () =>
      $larafetch(`extended-collections/${data.value.hashid}/personalisations/default`)
    );

    defaultPersonalisation = data;
  }

  await navigateTo(`/experiences/${data.value.collection.slug}-${defaultPersonalisation.hashid}`, {
    replace: true,
    redirectCode: 301,
  });
}

// In the case of collaborator versions where the version is not returned, set the current version to the default version
if (!currentPersonalisation.value) {
  currentPersonalisation.value = getDefaultPersonalisation();
}

// Guest preview
const { minimalUI } = useLayout();

// Welcome back existing/new user modal
const { $modal } = useNuxtApp();
const showModal = ref();

if (process.client && isAuthorisedUser.value) {
  showModal.value = localStorage.getItem("show_modal");

  if (showModal) {
    // const component = showModal === "existing_user" ? resolveComponent("LazyWelcomeExistingUserModal") : resolveComponent("LazyWelcomeNewUserModal");
    // $modal.open(component);
  }
}

const dismissNewUserMessage = () => {
  localStorage.removeItem("show_modal");
  showModal.value = null;
};

const isExpired = data.value.collection.expires_at && new Date(data.value.collection.expires_at) < new Date();

// Head
useHead({
  title: data.value.collection.name_clean,
  titleTemplate: "%s",
  meta: [
    // OpenGraph
    { property: "fb:app_id", content: fbAppId },
    { property: "og:type", content: "article" },
    { property: "og:url", content: data.value.seo.canonical_url },
    { property: "og:title", content: data.value.seo.title },
    { property: "og:description", content: data.value.seo.description },
    { property: "og:image", content: data.value.seo.image_url },
    { property: "og:locale", content: "en" },
    { property: "og:site_name", content: siteName },
    // Twitter Card
    { name: "twitter:card", content: "summary" },
    { name: "twitter:title", content: data.value.seo.title },
    { name: "twitter:description", content: data.value.seo.description },
    { name: "twitter:image", content: data.value.seo.image_url },
  ],
  script: [{ src: "//w.soundcloud.com/player/api.js" }],
});

// Google Fonts
const usesGoogleFonts = computed(
  () =>
    data.value &&
    (data.value.collection.customisations.body_font_type === "google-font" ||
      data.value.collection.customisations.collection_title_font_type === "google-font" ||
      data.value.collection.customisations.content_title_font_type === "google-font")
);

if (usesGoogleFonts.value) {
  useHead({
    link: [
      { rel: "preconnect", href: "https://fonts.googleapis.com" },
      {
        rel: "preconnect",
        href: "https://fonts.gstatic.com",
        crossorigin: true,
      },
      {
        rel: "stylesheet",
        href: `https://fonts.googleapis.com/css2?family=${data.value.collection.customisations.google_fonts?.uri}&display=swap`,
      },
    ],
  });
}

definePageMeta({
  layout: false,
});
</script>
